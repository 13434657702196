import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, PageEvent } from '@angular/material';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { DominioItemModel, ErrorModel, PageableResponseModel, requestItemsParams } from '@datasus/core';
import { DialogService, LayoutService } from '@datasus/layout';

import { MensagemEnum } from '../../enums/mensagem.enum';
import { TipoDemandaEnum } from '../../enums/tipo-demanda.enum';
import { TipoDemandaModel } from '../../models/tipo-demanda.model';

import { CategoriaService } from '../../../rotina/tabela/produto/services/categoria.service';
import { ItemService } from '../../services/item.service';
import { ItemModel } from '../../../rotina/tabela/produto/models/item.model';

interface DialogPesquisaItemData {
  idItem: number;
}

@Component({
  selector: 'scodes-dialog-pesquisa-item',
  templateUrl: './dialog-pesquisa-item.component.html',
  styleUrls: ['./dialog-pesquisa-item.component.scss'],
})
export class DialogPesquisaItemComponent implements OnInit {
  formGroup: FormGroup;

  loadingCategoria = false;
  categorias: DominioItemModel[];

  @ViewChild('paginator')
  paginator: MatPaginator;

  displayedColumns = [
    'acao',
    'codigo',
    'descricao',
    'judicial',
    'adm_com_farma',
    'jefaz'
  ];

  total = 0;
  pageSize = 10;
  items: ItemModel[];

  loading = false;
  onMobile$: Observable<boolean>;

  constructor(
    private formBuilder: FormBuilder,
    private layoutService: LayoutService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<DialogPesquisaItemComponent>,
    private categoriaService: CategoriaService,
    private itemService: ItemService,
    @Inject(MAT_DIALOG_DATA) public data: DialogPesquisaItemData
  ) { }

  ngOnInit(): void {
    this.onMobile$ = this.layoutService.onMobile();

    this.formGroup = this.formBuilder.group({
      idCategoria: [null],
      descricao: [null],
      codigo: [null]
    });

    this.items = [];
    this.loadCategorias();
  }

  search(event: Event): void {
    event.preventDefault();
    if (Object.values(this.formGroup.value).filter(v => !!v).length === 0) {
      return this.dialogService.showNotification(MensagemEnum.ONE_FILTER_REQUIRED);
    }
    this.requestItems();
  }

  requestItems(event?: PageEvent): void {
    this.loading = true;
    this.itemService.obterTodosPaginado({
      filter: this.formGroup.value,
      ...requestItemsParams({
        to: {pageSize: this.pageSize},
        changingPageSizeDefaultTo: this.pageSize,
        withPageEvent: event,
        withSort: {active: 'id', direction: 'asc'},
      }),
    })
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        (page: PageableResponseModel<ItemModel>) => {
          this.total = page.total;
          this.items = page.items || [];

          if (!this.items.length) {
            return this.dialogService.showNotification(MensagemEnum.NO_RESULTS_FOUND);
          }
        },
        (error: ErrorModel) => this.dialogService.showAlert(error.message),
      );
  }

  select(item: ItemModel): void {
    this.dialogRef.close(item);
  }

  close(event: MouseEvent): void {
    this.dialogRef.close();
  }

  resetForm(event: Event) {
    setTimeout(() => {
      this.items = [];
    });
  }

  isJudicial(tiposDemandas: TipoDemandaModel[]): boolean {
    return Array.isArray(tiposDemandas) ?
      tiposDemandas.some(td => td.id === TipoDemandaEnum.JUDICIAL) :
      false;
  }

  isAdmComFarma(tiposDemandas: TipoDemandaModel[]): boolean {
    return Array.isArray(tiposDemandas) ?
      tiposDemandas.some(td =>
        td.id === TipoDemandaEnum.ADMINISTRATIVA ||
        td.id === TipoDemandaEnum.COMISSAO_FARMACOLOGIA
      ) :
      false;
  }

  isJefaz(tiposDemandas: TipoDemandaModel[]): boolean {
    return Array.isArray(tiposDemandas) ?
      tiposDemandas.some(td => td.id === TipoDemandaEnum.JEFAZ) :
      false;
  }

  private loadCategorias(): void {
    this.loadingCategoria = true;
    this.categoriaService.obterDominio()
      .pipe(finalize(() => this.loadingCategoria = false))
      .subscribe(
        (data) => this.categorias = data,
        (error: ErrorModel) => this.dialogService.showAlert(error.message)
      );
  }
}
