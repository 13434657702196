/**
 * @description
 * Mensagens padrões do sistema
 *
 * @usageNotes
 * ```
 * MensagemEnum.BOM_DIA
 * // Bom dia! ...
 * MensagemEnum['BOM_DIA']
 * // Bom dia! ...
 * ```
 */
export enum MensagemEnum {
  // Sucesso
  REGISTRO_SALVO = 'Registro salvo com sucesso.',
  SENHA_ALTERADA = 'Senha alterada com sucesso.',
  REGISTRO_EXCLUIDO = 'Registro Excluído com sucesso.',
  INVENTARIO_CONSOLIDADO = 'Inventário consolidado com sucesso.',
  ITEM_ADICIONADO = 'Item adicionado com sucesso.',
  ITEM_REMOVIDO = 'Item removido com sucesso.',
  TODOS_ITENS_REMOVIDOS = 'Todos itens removidos com sucesso.',

  // Erro
  LISTA_ITEM_VAZIA = 'Adicione pelo menos um item na lista.',
  ITEM_JA_NA_LISTA = 'O item selecionado já está na lista.',
  QTD_SAIDA_ITEM = 'Quantidade maior do que o valor em estoque.',
  ITEM_SELECIONADO_IGUAL_ATUAL = 'O item selecionado não pode ser o mesmo item atual.',

  // Confirma
  CONFIRMA_REMOCAO = 'Deseja mesmo remover?',
  CONFIRMA_LANCAMENTO_INVENTARIO = 'Deseja realmente salvar este lançamento?',
  CONFIRMA_REMOCAO_ITEM_SIMILAR = 'Deseja realmente excluir este item similar?',
  CONFIRMA_REMOCAO_TODOS_ITENS_SIMILARES = 'Deseja realmente excluir todos os itens similares?',

  // Validações
  REQUIRED = 'Campo obrigatório.',
  DATE = 'Data inválida.',
  EMAIL = 'E-mail inválido.',
  MIN_LENGTH = 'Informe no mínimo {requiredLength} caracteres.',
  MAX_LENGTH = 'Informe no máximo {requiredLength} caracteres.',
  FORM_INVALID = 'Preencha os campos corretamente.',
  FILTERS_REQUIRED = 'Preencha os filtros obrigatórios.',
  ONE_FILTER_REQUIRED = 'Prencha ao menos um filtro para realizar a pesquisa.',
  NO_RESULTS_FOUND = 'Nenhum registro encontrado.',
  CONFIRM_PASSWORD = 'As senhas não conferem.',
  ONLY_DIGITS = 'Informe somente dígitos.',
}

export namespace MensagemEnum {
  /**
   * @description
   * Retorna uma mensagem formatada com valores informados
   *
   * @usageNotes
   * ```
   * MensagemEnum._('BOM_DIA')
   * // {nomePessoa}, bom dia! ...
   *
   * MensagemEnum._(MensagemEnum.BOM_DIA)
   * // {nomePessoa}, bom dia! ...
   *
   * MensagemEnum._('BOM_DIA', { nomePessoa: 'Goku' })
   * // Goku, bom dia! ...
   *
   * MensagemEnum._(MensagemEnum.BOM_DIA, { nomePessoa: 'Goku' })
   * // Goku, bom dia! ...
   * ```
   */
  export function _(msgText: string, replaceValues?: Object): string {
    let message = MensagemEnum[msgText] || msgText;
    if (replaceValues) {
      Object.entries(replaceValues).forEach(([key, value]) => {
        message = message.replace(`{${key}}`, value);
      });
    }
    return message;
  }
}
