import { Injectable } from '@angular/core';
import { BaseService, DominioItemModel } from '@datasus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService extends BaseService {

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/categoria/dominio');
  }
}
