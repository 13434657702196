import { DialogService } from '@datasus/layout';

const MAX_FILE_SIZE = 2048000;
const MAX_FILE_SIZE_LEGIVEL = '2 MB';

export class EditorFileToDataUrlAdapter {

  constructor(
    public loader: any,
    public dialogService: DialogService
  ) { }

  upload() {
    return this.loader.file.then((file: File) => new Promise((resolve, reject) => {
      if (file.size > MAX_FILE_SIZE) {
        console.error('EditorFileUploaderAdapter: file', file);
        this.dialogService.showAlert(
          `Inclusão da imagem não permitida. O arquivo deve ter tamanho máximo de ${MAX_FILE_SIZE_LEGIVEL}.`
        );
        return reject();
      }
      const onError = () => {
        console.error('EditorFileUploaderAdapter: file reader error');
        this.dialogService.showAlert(
          `Inclusão da imagem falhou.`
        );
        reject();
      };
      const reader = new FileReader();
      reader.onloadend = () => resolve({ default: reader.result });
      reader.onerror = onError;
      reader.onabort = onError;
      reader.readAsDataURL(file);
    }));
  }

  abort() {
    console.error('EditorFileUploaderAdapter: abort');
  }

}
