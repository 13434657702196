import { NgModule } from '@angular/core';

import { SharedComponentsModule } from './components/shared-components.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';

@NgModule({
  exports: [
    SharedComponentsModule,
    SharedDirectivesModule,
    SharedPipesModule,
  ],
})
export class SharedModule { }
