import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, DominioItemModel, PageableResponseModel, PageableRequestModel } from '@datasus/core';

import { ItemModel } from '../../rotina/tabela/produto/models/item.model';
import { ItemFilterModel } from '../../rotina/tabela/produto/models/item-filter.model';

@Injectable({
  providedIn: 'root'
})
export class ItemService extends BaseService {

  obterPorId(id: number): Observable<ItemModel> {
    return this.baseGet<ItemModel>(`/item/${id}`);
  }

  obterAutocompletePorCodigo(codigo: string): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>(`/item/codigo/autocomplete?codigo=${codigo}`);
  }

  obterTodosPaginado(body: PageableRequestModel<ItemFilterModel>): Observable<PageableResponseModel<ItemModel>> {
    return this.basePost<PageableResponseModel<ItemModel>>('/item/paginado', body);
  }

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/item/dominio');
  }

  incluir(body: ItemModel): Observable<ItemModel> {
    return this.basePost<ItemModel>('/item', body);
  }

  alterar(body: ItemModel, id: number): Observable<ItemModel> {
    return this.basePut<ItemModel>(`/item/${id}`, body);
  }

  excluir(id: number): Observable<void> {
    return this.baseDelete<void>(`/item/${id}`);
  }

  obterItensSimilaresPorIdItem(id: number): Observable<ItemModel[]> {
    return this.baseGet<ItemModel[]>(`/item/${id}/similares`);
  }

  alterarSimilaridadePorIdItem(body: number[], id: number): Observable<ItemModel[]> {
    return this.basePut<ItemModel[]>(`/item/${id}/similares`, body);
  }

}
