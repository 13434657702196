import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { NameDirective } from './name/name.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    // NameDirective,
  ],
  exports: [
    // NameDirective,
  ],
})
export class SharedDirectivesModule { }
