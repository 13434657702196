import { NgModule } from '@angular/core';

import { CoreModule } from '@datasus/core';
import { LayoutModule } from '@datasus/layout';
import { CommonsModule } from '@datasus/commons';
import { FormsModule } from '@datasus/forms';

import * as packageJson from '../../package.json';

@NgModule({
  imports: [
    CoreModule,
    LayoutModule.forRoot({
      withGovBrBar: true,
      appName: 'SCODES',
      appVersion: packageJson.version,
    }),
    CommonsModule.forRoot({
      welcomeText: 'Bem-vindo!',
      welcomeHtml: `
        <h2 class="mat-display-2 align-center">SCODES</h2>
      `,
    }),
    FormsModule,
  ],
  exports: [
    LayoutModule,
    CommonsModule,
    FormsModule,
  ],
})
export class DatasusModule { }
