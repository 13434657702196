import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { MaterialModule } from '../../../material/material.module';
import { DatasusModule } from '../../../datasus/datasus.module';

import { DialogPesquisaItemComponent } from './dialog-pesquisa-item/dialog-pesquisa-item.component';
import { EditorComponent } from './editor/editor.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    DatasusModule,
    CKEditorModule,
  ],
  declarations: [
    DialogPesquisaItemComponent,
    EditorComponent
  ],
  exports: [
    DialogPesquisaItemComponent,
    EditorComponent
  ],
  entryComponents: [
    DialogPesquisaItemComponent,
  ]
})
export class SharedComponentsModule { }
