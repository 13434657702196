import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { routesDefault, guardsDefault } from '@datasus/commons';

// import { environment } from '../environments/environment';

const appRoutes: Routes = [
  {
    path: 'controle-acesso/grupo',
    loadChildren: './controle-acesso/grupo/grupo.module#GrupoModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'controle-acesso/perfil',
    loadChildren: './controle-acesso/perfil/perfil.module#PerfilModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'controle-acesso/usuario',
    loadChildren: './controle-acesso/usuario/usuario.module#UsuarioModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'controle-acesso/acesso',
    loadChildren: './controle-acesso/acesso/acesso.module#AcessoModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/tipo-acao',
    loadChildren: './rotina/tabela/tipo-acao/tipo-acao.module#TipoAcaoModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/tipo-documento',
    loadChildren: './rotina/tabela/tipo-documento/tipo-documento.module#TipoDocumentoModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/natureza-despesa',
    loadChildren: './rotina/tabela/natureza-despesa/natureza-despesa.module#NaturezaDespesaModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/unidade-transferencia',
    loadChildren: './rotina/tabela/unidade-transferencia/unidade-transferencia.module#UnidadeTransferenciaModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/classificacao-cobranca',
    loadChildren: './rotina/tabela/classificacao-cobranca/classificacao-cobranca.module#ClassificacaoCobrancaModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/plano-saude',
    loadChildren: './rotina/tabela/plano-saude/plano-saude.module#PlanoSaudeModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/modelo-oficio',
    loadChildren: './rotina/tabela/modelo-oficio/modelo-oficio.module#ModeloOficioModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/modelo-relatorio-tecnico',
    loadChildren: './rotina/tabela/modelo-relatorio-tecnico/modelo-relatorio-tecnico.module#ModeloRelatorioTecnicoModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/local-jefaz',
    loadChildren: './rotina/tabela/local-jefaz/local-jefaz.module#LocalJefazModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/tipo-cobranca',
    loadChildren: './rotina/tabela/tipo-cobranca/tipo-cobranca.module#TipoCobrancaModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/produto',
    loadChildren: './rotina/tabela/produto/produto.module#ProdutoModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/similaridade',
    loadChildren: './rotina/tabela/similaridade/similaridade.module#SimilaridadeModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/gestao-estoque',
    loadChildren: './rotina/gestao-estoque/gestao-estoque.module#GestaoEstoqueModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/inventario',
    loadChildren: './rotina/inventario/inventario.module#InventarioModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'dados-pessoais',
    loadChildren: './dados-pessoais/dados-pessoais.module#DadosPessoaisModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/alteracao-data-retorno',
    loadChildren: './rotina/alteracao-data-retorno/alteracao-data-retorno.module#AlteracaoDataRetornoModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'demanda',
    loadChildren: './demandas/demandas.module#DemandasModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/vara',
    loadChildren: './rotina/tabela/vara/vara.module#VaraModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/tabela/profissional',
    loadChildren: './rotina/tabela/profissional/profissional.module#ProfissionalModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
  {
    path: 'rotina/inativar-demanda',
    loadChildren: './rotina/inativar-demanda/inativar-demanda.module#InativarDemandaModule',
    canActivate: [
      ...guardsDefault,
    ],
    canLoad: [
      ...guardsDefault,
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot([...appRoutes, ...routesDefault], {
      useHash: false,
      // enableTracing: !environment.production,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule { }
