import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { CKEditor5 } from '@ckeditor/ckeditor5-angular/ckeditor';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/pt-br';

import { DialogService, LayoutService } from '@datasus/layout';
import { EditorFileToDataUrlAdapter } from './editor-file-to-data-url-adapter';

@Component({
  selector: 'scodes-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy {

  editor = DecoupledEditor;
  editorConfig: CKEditor5.Config;

  @Input() data: string;
  @Input() disabled: boolean;

  @Output() dataChange = new EventEmitter<string>(false);

  @ViewChild('editorLocalRef') editorLocalRef: any;

  private onMobileSubscription: Subscription;

  constructor(
    private dialogService: DialogService,
    private layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
    this.onMobileSubscription = this.layoutService.onMobile().pipe(
      map((onMobile: boolean) => ({
        dialogService: this.dialogService,
        language: 'pt-br',
        extraPlugins: [this.fileUploaderAdapterPlugin],
        removePlugins: ['MediaEmbed'],
          toolbar: this.getToolbar(onMobile),
      })),
    ).subscribe(
      (config: CKEditor5.Config) => this.editorConfig = config,
      (error: any) => console.error(error),
    );
  }

  ngOnDestroy(): void {
    this.onMobileSubscription.unsubscribe();
  }

  getToolbar(onMobile: boolean): string[] {

    if (this.disabled) {
      return null;
    } else {
      const separator = '|';
      const fontStyle = [
        'bold',
        'italic',
        'underline',
      ];
      const paragraphIndentation = [
        'indent',
        'outdent',
      ];
      let alignment = [
        'alignment:left',
        'alignment:center',
        'alignment:right',
        'alignment:justify',
      ];
      let undoRedo = [
        'undo',
        'redo',
      ];

      if (onMobile) {
        alignment = [ 'alignment' ];
        undoRedo = [ 'undo' ];
      }

      return [
        ...fontStyle,
        separator,
        ...alignment,
        separator,
        ...paragraphIndentation,
        separator,
        ...undoRedo,
        separator,
        'imageUpload',
      ];
    }
  }

  onChange({ editor }: ChangeEvent) {
    if (editor) {
      const data = editor.getData();
      this.data = data;
      this.dataChange.emit(this.data);
    }
  }

  onReady(editor: any) {
    if (editor) {
      editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
    }
  }

  reset() {
    this.setEditorData('');
  }

  setEditorData(data: string) {
    this.data = data;
    this.editorLocalRef.editorInstance.setData(this.data);
    this.dataChange.emit(this.data);
  }

  fileUploaderAdapterPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return new EditorFileToDataUrlAdapter(loader, editor.config._config.dialogService);
    };
  }

}
